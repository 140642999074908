import axios from 'axios';
import {API_BASE_URL} from './constants';


export const endpoints = {

    sendMail: function (data) {
        return axios({
            method: 'post',
            url: API_BASE_URL + '/email/send.php',
            data: data
        });
    },
    signUp: function (data) {
        return axios({
            method: 'post',
            url: 'https://claim-api.btu-direct.com/exporter',
            data: data
        });
    }
};