import React, {Component} from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from "react-router-dom"
//import $ from 'jquery';
import BtuHome from "../screens/BtuHome"
import BtuDownload from "../screens/BtuDownload"
// import BtuSignUp from "../screens/BtuSignUp"
import BtuResend from "../screens/BtuResend"

// Redirect to /
class RedirectHome extends React.Component {
  render() {
    this.props.history.push('/')
    return (null)
  }
}

class BtuRouter extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={() => <BtuHome useSuspense={false}/>}/>
          <Route exact path="/download" render={() => <BtuDownload useSuspense={false}/>}/>
          <Route exact path="/resend/:query" render={(props) => <BtuResend useSuspense={false} {...props} />} />
          <Route exact path="/:query" render={(props) => <BtuHome useSuspense={false} {...props} />} />
          {/*<Route exact path="/sign-up" render={() => <BtuSignUp useSuspense={false}/>} />*/}
          <Route exact path="/*" component={withRouter(RedirectHome)} />
        </Switch>
      </Router>
    )
  }
}

export default BtuRouter