import {
    PROCESSING,
    SEND_MAIL_SUCCESS,
    SEND_MAIL_FAILURE,
    SET_USER_LOCALE,
    TEXT_CHANGE,
    TOGGLE_COLLAPSE,
    TOGGLE_MODAL,
    HANDLE_FIELDS_VALIDITY,
    SIGN_UP_SUCCESS,
    SIGN_UP_FAILURE, SELECT_CHANGE, CHECKBOX_CHANGE, RESET_FORM
} from './actionTypes';

import {endpoints} from '../api/endpoints';
import {checkField} from '../utils/utils';
import {FORM_CONTACT, FORM_SIGN_UP} from "../utils/constants";

import frResources from '../translations/fr/resources_fr';
import enResources from '../translations/en/resources_en';


// Set the user locale
export function setUserLocale(locale) {
    return {
        type: SET_USER_LOCALE,
        payload: {
            userLocale: locale
        }
    }
}

// Toggle collapse Show/Hide actions
export function toggleCollapse() {
    return {
        type: TOGGLE_COLLAPSE
    }
}

// Toggle modal Open/Close actions
export function toggleModal(modalAction = null) {
    return {
        type: TOGGLE_MODAL,
        payload: {
            modalAction: modalAction
        }
    }
}

// Handle form text input change
export function textChange(formName, inputName, inputValue) {
    return {
        type: TEXT_CHANGE,
        payload: {
            formName: formName,
            data: {
                [inputName]: inputValue
            }
        }
    }
}


// Handle checkbox input
export function checkboxChange(formName, checkName, checked) {
    return {
        type: CHECKBOX_CHANGE,
        payload: {
            formName: formName,
            data: {
                [checkName]: checked
            }
        }
    }
}

// Handle form text input change
export function selectChange(formName, selectName, selectValue) {
    return {
        type: SELECT_CHANGE,
        payload: {
            formName: formName,
            data: {
                [selectName]: selectValue
            }
        }
    }
}

// Handle loading indicator when process is triggered
export function loading(bool) {
    return {
        type: PROCESSING,
        payload: {
            isLoading: bool
        }
    }
}

// Handle Form Reset
export function resetSignUpForm() {
    return {
        type: RESET_FORM,
    }
}


// Handle form fields validity
export function handleValidity(formName, bool) {
    return {
        type: HANDLE_FIELDS_VALIDITY,
        payload: {
            formName: formName,
            data: {
                isValidFields: bool
            }
        }
    }
}


// Mail sending success case
export function sendMailSuccess() {
    return {
        type: SEND_MAIL_SUCCESS,
    }
}

// Mail sending success case
export function sendMailFailure() {
    return {
        type: SEND_MAIL_FAILURE,
    }
}

// Send mail form
export function sendMail() {
    return (dispatch, getState) => {
        let formContact = getState().btu[FORM_CONTACT];
        dispatch(loading(true));

        if(
            !checkField(formContact.name) ||
            !checkField(formContact.email) ||
            !checkField(formContact.subject) ||
            !checkField(formContact.message)
        )
        {
            dispatch(loading(false));
            dispatch(handleValidity(FORM_CONTACT,false));
            return;
        }

        dispatch(handleValidity(FORM_CONTACT,true));

        let message = formContact.message
        if (formContact.wallet) {
          message = 'Adresse wallet : ' + formContact.wallet + '\r\n\r\n' + message
        }

        endpoints.sendMail(
            {
                name: formContact.name,
                email: formContact.email,
                subject: formContact.subject,
                message,
                mailType: 'contact'
            }
        ).then(function (response) {
            dispatch(loading(false));

            if('status' in response && response.status) {
                dispatch(sendMailSuccess());
            }
            else {
                dispatch(sendMailFailure());
            }
        })
        .catch(function (error) {
            dispatch(loading(false));
            dispatch(sendMailFailure());
            console.log(error);
        });
    }
}

// Mail sending success case
export function signUpSuccess() {
    return {
        type: SIGN_UP_SUCCESS,
    }
}

// Mail sending success case
export function signUpFailure() {
    return {
        type: SIGN_UP_FAILURE,
    }
}

// Handle form text input change
export function signUp(translate) {
    return (dispatch, getState) => {
        let signUpForm = getState().btu[FORM_SIGN_UP], userLocale = getState().btu.userLocale;

        dispatch(loading(true));

        if(
            //!checkField(signUpForm.username) ||
            !checkField(signUpForm.email) ||
            !checkField(signUpForm.phoneNumber)||
            !signUpForm.cgu
        )
        {
            dispatch(loading(false));
            dispatch(handleValidity(FORM_SIGN_UP,false));
            return;
        }

        dispatch(handleValidity(FORM_SIGN_UP,true));

        endpoints.signUp(
            {
                telephone: signUpForm.phoneCode + '' + signUpForm.phoneNumber,
                email: signUpForm.email,
                walletAddress: window.walletAddress,
                from: signUpForm.from
            }
        ).then(function (response) {
            if('res' in response.data && response.data.res === 'ok') {

                let walletStr = window.walletAddress ? '?w=' + window.walletAddress : ''
                enResources.sign_up_mail_body.desktop.steps[1] = enResources.sign_up_mail_body.desktop.steps[1].replace('%walletAddress%', walletStr)
                enResources.sign_up_mail_body.android.steps[0] = enResources.sign_up_mail_body.android.steps[0].replace('%walletAddress%', walletStr)
                enResources.sign_up_mail_body.apple.steps[0] = enResources.sign_up_mail_body.apple.steps[0].replace('%walletAddress%', walletStr)
                frResources.sign_up_mail_body.desktop.steps[1] = frResources.sign_up_mail_body.desktop.steps[1].replace('%walletAddress%', walletStr)
                frResources.sign_up_mail_body.android.steps[0] = frResources.sign_up_mail_body.android.steps[0].replace('%walletAddress%', walletStr)
                frResources.sign_up_mail_body.apple.steps[0] = frResources.sign_up_mail_body.apple.steps[0].replace('%walletAddress%', walletStr)

                endpoints.sendMail(
                    {
                        email: signUpForm.email,
                        subject: translate('sign_up_mail_subject'),
                        message: userLocale && userLocale.startsWith('en') ? enResources.sign_up_mail_body : frResources.sign_up_mail_body,
                        mailType: 'signUp'
                    }
                ).then(function (response) {
                    dispatch(loading(false));

                    if('status' in response && response.status) {
                        dispatch(signUpSuccess());
                    }
                    else {
                        dispatch(signUpFailure());
                    }
                })
                    .catch(function (error) {
                        dispatch(loading(false));
                        dispatch(signUpFailure());
                        console.log(error);
                    });

            }
            else {
                dispatch(loading(false));
                dispatch(signUpFailure());
            }
        })
            .catch(function (error) {
                dispatch(loading(false));
                dispatch(signUpFailure());
                console.log(error);
            });
    }
}