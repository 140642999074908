import DappBrowser from './DappBrowser'

class ModernMetamaskBrowser extends DappBrowser {
  getWalletType() {
    return "metamask"
  }

  getNetworkId() {
    return new Promise((resolve, reject) => {
      this.web3.eth.net.getId((error, result) => {
        if (error || !result) {
          reject(new Error("Cannot get network id"))
        } else {
          resolve(result)
        }
      })
    })
  }

  getCoinbase(isManual = false) {
    if (window.ethereum && isManual) {
      return new Promise((resolve, reject) => {
        window.ethereum.enable().then(() => {
          this.web3.eth.getCoinbase((error, result) => {
            if (error || !result) {
              reject(new Error("Cannot get coinbase"))
            } else {
              resolve(result)
            }
          })
        }).catch(err => {
          reject(new Error("Cannot get coinbase"))
        })

      })
    } else {
      return new Promise((resolve, reject) => {
        this.web3.eth.getCoinbase((error, result) => {
          if (error || !result) {
            reject(new Error("Cannot get coinbase"))
          } else {
            resolve(result)
          }
        })
      })
    }
  }

  setDefaultAddress(address) {
    this.web3.eth.defaultAccount = address
  }
}

export default ModernMetamaskBrowser