import React from 'react'
import { withRouter } from 'react-router-dom'

/**
 * Page 'download' : redirection vers un store sur mobile ou l'accueil sur desktop
 */
class BtuDownload extends React.PureComponent {

  /**
   * Redirection dès que le composant est monté
   */
  componentDidMount() {
    const {
      history,
    } = this.props

    if (/Android/i.test(navigator.userAgent)) {
      //window.location.href = 'market://details?id=com.btu_direct.wallet'
      window.location.href = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.btu_direct.wallet&ddl=1&pcampaignid=web_ddl_1'
      history.push('/')
    }
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.location.href = 'itms-apps://apps.apple.com/fr/app/btu-protocol/id1473117679'
      history.push('/')
    }
    else
      history.push('/')
  }

  render() {
    return (
      <div />
    )
  }
}

export default withRouter(BtuDownload)
