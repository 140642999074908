import DappBrowser from './DappBrowser'

class TrustBrowser extends DappBrowser {

  getCoinbase() {
    return new Promise((resolve, reject) => {
      this.web3.eth.getCoinbase((error, result) => {
        if (error || !result) {
          reject(new Error("Cannot get coinbase"))
        } else {
          resolve(result)
        }
      })
    })
  }

  getWalletId() {
    return "0x7A20d9b9B6dEAdD0260CBEcd66A35d5d370e04FE";
  }
}

export default TrustBrowser