import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from "react-i18next"

import { endpoints } from '../api/endpoints'

import frResources from '../translations/fr/resources_fr'
import enResources from '../translations/en/resources_en'

import {
  Card,
  CardBody,
} from "reactstrap"

class BtuResend extends Component {
  state= {
    w: null,
    wValid: false,
    email: null,
    emailValid: false,
    emailSuccess : false,
    emailError: false,
  }

  componentDidMount() {
    const queryData = {
      w: null,
      wValid: false,
      email: null,
      emailValid: false,
    }

    // On parse la query passée en url
    const { query } = this.props.match.params

    // Découpage de la query par le séparateur « & »
    const queryArray = query.split('&')

    for (let i = 0; i < queryArray.length; i++) {
      // Pour chaque élément de la query : séparation de la clé de sa valeur
      const queryElement = queryArray[i].split('=')
      if (Array.isArray(queryElement) && queryElement.length) {
        const key = queryElement[0]
        const value = queryElement[1]
        queryData[key] = decodeURIComponent(value)
      }
    }

    // Validation de l'adresse ETH
    if (queryData.w) {
      const ethAddressPattern = /^0[xX][0-9a-zA-Z]{40}$/
      queryData.wValid = ethAddressPattern.test(queryData.w)
    }

    // Validation de l'email
    if (queryData.email) {
      const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      queryData.emailValid = emailPattern.test(queryData.email)
    }

    this.setState(queryData, () => {
      this.resendEmail()
    })
  }

  resendEmail = () => {
    const {
      userLocale,
    } = this.props

    const {
      email,
      emailValid,
      wValid,
    } = this.state

    if (wValid && emailValid) {
      endpoints.sendMail({
        email: email,
        subject: this.props.t('sign_up_mail_subject'),
        message: userLocale && userLocale.startsWith('en') ? enResources.sign_up_mail_body : frResources.sign_up_mail_body,
        mailType: 'signUp'
      })
        .then(response => {
          this.setState({
            emailSuccess: ('status' in response && response.status),
            emailError: false,
          })
        })
        .catch(error => {
          console.log(error)
          this.setState({
            emailSuccess: false,
            emailError: true,
          })
        })
    }
  }

  render() {
    const {
      email,
      emailValid,
      w,
      wValid,
      emailSuccess,
      emailError,
    } = this.state

    let error = 'Erreur'
    if (!emailValid || !wValid) {
      error = 'Erreur : mauvais paramètres'
    } else if (emailError || emailSuccess) {
      error = 'Erreur lors de l\'envoi de l\'e-mail'
    }

    return (
      <section id={'resend'} className={'d-flex align-items-center justify-content-center'} style={{ marginTop: 20 }}>
        <Card className={'pt-3 pb-3 pl-2 pr-2'} style={{ paddingLeft: 20, paddingRight: 20 }}>
          <CardBody>
            <h3>Renvoi d'e-mail</h3>
            { emailValid && wValid && emailSuccess && !emailError
            ? (
                <p>
                  L'e-mail a été correctement renvoyé.
                </p>
              )
            : (
                <p style={{ color: 'red' }}>
                  {error}
                </p>
              )
            }
            <ul>
            <li>
                <strong>E-mail : </strong>
                <span style={{
                  color: emailValid ? 'black' : 'red'
                }}>
                  { email
                    ? email
                    : (
                      <i>Manquant</i>
                      )
                  }
                </span>
              </li>
              <li>
                <strong>Adresse ETH : </strong>
                <span style={{
                  color: wValid ? 'black' : 'red'
                }}>
                  { w
                    ? w
                    : (
                      <i>Manquante</i>
                      )
                  }
                </span>
              </li>
            </ul>
          </CardBody>
        </Card>
      </section>
    )
  }
}

BtuResend.propTypes = {}

const mapStateToProps = (state) => {
  return {
    userLocale: state.btu.userLocale,
    isLoading: state.btu.isLoading,
    isOpenModal: state.btu.isOpenModal,
  }
}

BtuResend = connect(mapStateToProps, null)(BtuResend)

export default withTranslation()(BtuResend)
