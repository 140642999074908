import React, {Component} from 'react';
import {connect} from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import './assets/css/line-awesome.min.css';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './assets/css/style.css';

import BtuRouter from './config/BtuRouter';
import {setUserLocale} from "./actions/actionCreators";
import i18next from 'i18next';

import getW3 from './web3/Web3Instance'

class App extends Component {

    componentDidMount() {
      // if (window.location.protocol == "http:")
	    //   window.location.protocol = "https://"


	// Detect user locale and save it to the store
        this.props.dispatch(setUserLocale(i18next.language));

        // Get web3 instance
        const w3 = getW3()
        // Watch for walletAddress change
        if (w3) {
          //-- Si on est sur la page d'accueil depuis un dapp brower autre que metamask -> redirection vers page sign-up
          /*
          if (w3.web3.currentProvider && w3.web3.currentProvider.host && w3.web3.currentProvider.host.indexOf('metamask') === -1)  {
            if (window.location.pathname === '/') {
              window.location.href = window.location.href + 'sign-up'
            }
          }
          */
          setInterval(() => {
                w3.web3.eth.getAccounts()
                .then((walletAddress) => {
                    window.walletAddress = walletAddress[0]
                })
            }, 1000)
        }
    }

    render() {
        return (
            <BtuRouter useSuspense={false}/>
        );
    }
}

App = connect(null, null)(App);
export default App;
