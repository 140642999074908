import DappBrowser from './DappBrowser'

class MetamaskBrowser extends DappBrowser {

  getCoinbase() {
    return new Promise((resolve, reject) => {
      this.web3.eth.getCoinbase((error, result) => {
        if (error || !result) {
          reject(new Error("Cannot get coinbase"))
        } else {
          resolve(result)
        }
      })
    })
  }

  getWalletType() {
    return "metamask"
  }
}

export default MetamaskBrowser