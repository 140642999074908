/* eslint-disable no-useless-escape */
import React, {Component} from 'react'

import {withTranslation} from "react-i18next"

import logo from "../assets/img/verso_logo.svg";
import background from "../assets/img/verso_background.jpg";

class BtuHome extends Component {
  componentDidMount() {
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        window.location.href = 'https://get-verso.com/';
      }
    }, 2000);
  }

  render() {
    return (
      <React.Fragment>
        <div className="versoContainer" style={{ backgroundImage: `url(${background})` }}>
          <span>BTU Direct devient</span>
          <img className="versoLogo" src={logo} alt="Verso" />
          <span className="versoBrand">Verso</span>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(BtuHome)