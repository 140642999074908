import TrustBrowser from './dapp/TrustBrowser'
import CipherBrowser from './dapp/CipherBrowser'
import MetamaskBrowser from './dapp/MetamaskBrowser'
import DappBrowser from './dapp/DappBrowser'
import ModernMetamaskBrowser from './dapp/ModernMetamaskBrowser'

function getNewWeb3Instance() {
  let type = getWeb3Type();
  let web3Instance = undefined;
  switch (type) {
    case "modern_metamask":
      web3Instance = new ModernMetamaskBrowser();
      break;
    case "trust":
      web3Instance = new TrustBrowser();
      break;
    case "cipher":
      web3Instance = new CipherBrowser();
      break;
    case "metamask":
      web3Instance = new MetamaskBrowser();
      break;
    case "dapp":
      web3Instance = new DappBrowser();
      break;
    case "unknown":
      web3Instance = new DappBrowser();
      break;
    default:
      web3Instance = undefined;
      break;
  }
  return web3Instance;
}

function getWeb3Type() {
  if (window.ethereum) {
    return "modern_metamask";
  }
  if (window.web3) {
    if (window.web3.currentProvider && window.web3.currentProvider.isMetaMask) {
      return "metamask";
    }
    if (window.web3.currentProvider && window.web3.currentProvider.isTrust === true) {
      return "trust";
    }
    if ((!!window.__CIPHER__) && (window.web3.currentProvider && window.web3.currentProvider.constructor && window.web3.currentProvider.constructor.name === "CipherProvider")) {
      return "cipher";
    }
    if (window.web3.isDAppBrowser && window.web3.isDAppBrowser()) {
      return "dapp";
    }
    return "unknown";
  }
  return "non_web3";
}

export default getNewWeb3Instance