import Web3 from 'web3'

class DappBrowser {

  constructor() {
    //web3.setProvider('https://ropsten.infura.io/v3/96c60344282c46bebeec8205fa7adadf');
    this.web3 = new Web3(window.web3.currentProvider);
  }

  getWalletType() {
    return "dapp"
  }

  getNetworkId() {
    return new Promise((resolve, reject) => {
      this.web3.eth.net.getId((error, result) => {
        if (error || !result) {
          reject(new Error("Cannot get network id"))
        } else {
          resolve(result)
        }
      })
    })
  }

  getCoinbase() {
    return new Promise((resolve, reject) => {
      this.web3.eth.getAccounts((error, result) => {
        if (error || result.length === 0) {
          reject(new Error("Cannot get coinbase"))
        } else {
          resolve(result[0])
        }
      })
    })
  }

  getAccounts() {
    return new Promise((resolve, reject) => {
      this.web3.eth.getAccounts((error, result) => {
        if (error || result.length === 0) {
          reject(new Error("Cannot get accounts"))
        } else {
          resolve(result[0])
        }
      })
    })
  }
}

export default DappBrowser